<template>
  <div v-if="dynamicLink">
    <p class="text-xl font-bold mb-1">Félicitations pour votre participation</p>
    <p class="text-xl font-bold text-gray-500 mb-7">
      Votre récompense vous attend !
    </p>
    <p class="mb-10">
      Vous avez franchi une étape importante en rejoignant notre communauté.
      Pour célébrer cela, nous vous offrons
      <span class="font-bold">un badge certifié horodaté</span>, symbole de
      votre engagement envers l'innovation et la technologie blockchain.
    </p>
    <div v-if="user" class="flex items-center mb-10">
      <div
        class="hidden lg:block flex-none w-[300px] bg-gray-900 rounded-2xl px-4 py-5"
      >
        <qrcode-vue
          v-if="dynamicLink"
          :value="dynamicLink"
          :size="250"
          level="H"
          class="bg-white mx-auto rounded-xl p-3 mb-5"
        />
        <div class="flex justify-center text-white px-2 mb-3">
          <icon name="scan" class="w-4 fill-white mr-2" />Scannez avec myDid
        </div>
        <p class="text-white text-xs px-2 mb-5">
          Seul le DID actuellement connecté pourra récupérer ce badge.
        </p>
      </div>
      <div class="grow pl-5">
        <div class="flex gap-5 mb-3">
          <p class="text-xl leading-none">•</p>
          <p class="hidden lg:block">
            <span class="font-bold">Scanner le QR Code</span> : Avec
            l'application myDid pour réclamer votre badge.
          </p>
          <p class="lg:hidden">
            <span class="font-bold">Cliquez sur "Récupérer votre badge"</span> :
            Pour ouvrir l'application myDid et réclamer votre badge.
          </p>
        </div>
        <div class="flex gap-5 mb-3">
          <p class="text-xl leading-none">•</p>
          <p class="hidden lg:block">
            <span class="font-bold">Un usage unique</span> : Ce QR Code est
            spécialement conçu pour être utilisé une seule fois. Il est lié à
            votre DID pour garantir l'unicité et la sécurité de votre badge.
          </p>
          <p class="lg:hidden">
            <span class="font-bold">Un usage unique</span> : Ce lien est
            spécialement conçu pour être utilisé une seule fois. Il est lié à
            votre DID pour garantir l'unicité et la sécurité de votre badge.
          </p>
        </div>
        <div class="flex gap-5 mb-3">
          <p class="text-xl leading-none">•</p>
          <p class="hidden lg:block">
            <span class="font-bold">Votre badge personnel</span> : Une fois
            scanné, le badge sera ajouté à votre portefeuille myDid, attestant
            de votre participation à notre démo.
          </p>
          <p class="lg:hidden">
            <span class="font-bold">Votre badge personnel</span> : Une fois le
            lien cliqué, le badge sera ajouté à votre portefeuille myDid,
            attestant de votre participation à notre démo.
          </p>
        </div>
      </div>
    </div>
    <div v-if="dynamicLink" class="flex lg:hidden ml-2 mb-3">
      <a :href="dynamicLink"
        ><p
          class="text-sm text-white text-center bg-secondary font-semibold w-56 rounded-lg py-3 px-5 cursor-pointer"
        >
          Récupérer votre badge
        </p></a
      >
    </div>
  </div>
  <custom-loader v-else color="#111827" size="70px" class="mt-10">
  </custom-loader>
</template>

<script>
import { ref, inject } from 'vue';
export default {
  setup() {
    const $cookies = inject('$cookies');
    const $api = inject('$api');
    const user = ref(null);
    const dynamicLink = ref(null);

    function logout() {
      $cookies.remove('token');
      window.location.replace('/');
    }

    function shortenString(str, maxLength) {
      if (str.length <= maxLength) {
        return str;
      }

      var middle = Math.floor(maxLength / 2);
      return str.substr(0, middle) + '...' + str.substr(str.length - middle);
    }

    $api.getUser().then((res) => {
      user.value = res.data;
      $api.generateBadgeBotSession(user.value.did).then((res) => {
        dynamicLink.value = res.data.links[0];
      });
    });

    return {
      user,
      logout,
      shortenString,
      dynamicLink,
    };
  },
};
</script>
