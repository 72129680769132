<template>
  <div class="0">
    <p class="text-xl mt-5">User list :</p>
    <div
      class="bg-gray-light border rounded-xl my-5 p-5"
      v-for="user in users"
      :key="user"
    >
      <p class="text-center text-l break-words mb-1">
        {{ user.did }}
      </p>
      <p
        v-if="user.verifiableCredentials.length > 0"
        class="text-center text-sm mb-1 mt-3"
      >
        Verifiable credentials :
      </p>
      <div
        v-for="verifiableCredential in user.verifiableCredentials"
        :key="verifiableCredential"
      >
        <p
          v-if="verifiableCredential.templateHash"
          class="text-center italic text-l break-words mb-1"
        >
          badge : {{ verifiableCredential.name }}
        </p>
        <p
          v-else-if="
            Object.keys(verifiableCredential.credentialSubject)[
              Object.keys(verifiableCredential.credentialSubject).length - 1
            ] != 'award'
          "
          class="text-center italic text-l break-words mb-1"
        >
          {{
            Object.keys(verifiableCredential.credentialSubject)[
              Object.keys(verifiableCredential.credentialSubject).length - 1
            ] +
            ' : ' +
            verifiableCredential.credentialSubject[
              Object.keys(verifiableCredential.credentialSubject)[
                Object.keys(verifiableCredential.credentialSubject).length - 1
              ]
            ]
          }}
        </p>

        <p v-else class="text-center italic text-l break-words mb-1">
          {{
            verifiableCredential.credentialSubject[
              Object.keys(verifiableCredential.credentialSubject)[
                Object.keys(verifiableCredential.credentialSubject).length - 1
              ]
            ].split('; ')[0] +
            ' : ' +
            verifiableCredential.credentialSubject[
              Object.keys(verifiableCredential.credentialSubject)[
                Object.keys(verifiableCredential.credentialSubject).length - 1
              ]
            ].split('; ')[1]
          }}
        </p>
      </div>
      <div class="flex justify-center mt-5">
        <div
          @click="deleteUser(user.did)"
          class="text-sm text-white text-center bg-secondary font-semibold w-56 rounded-lg py-3 px-5 cursor-pointer"
        >
          Delete
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, inject } from 'vue';
import { useRouter } from 'vue-router';
export default {
  setup() {
    const $cookies = inject('$cookies');
    const $api = inject('$api');
    const router = useRouter();
    const users = ref({});

    function logout() {
      $cookies.remove('token');
      router.push('/');
    }

    $api.isAdmin().then((res) => {
      const isAdmin = res.data.isAdmin;
      if (!isAdmin) {
        window.location.replace('/user');
      } else {
        $api.getAllUsers().then((res) => {
          users.value = res.data.reverse();
        });
      }
    });

    async function deleteUser(did) {
      await $api.deleteUser(did);
      users.value = (await $api.getAllUsers()).data.reverse();
    }

    return {
      users,
      logout,
      deleteUser,
    };
  },
};
</script>
