import { createApp } from 'vue';

import './styles.css';
import 'mosha-vue-toastify/dist/style.css';
import '@fortawesome/fontawesome-free/js/all.js';
import ClipLoader from 'vue-spinner/src/ClipLoader.vue';
import VueCookies from 'vue-cookies';
import QrCodeVue from 'qrcode.vue';
import VueMatomo from 'vue-matomo';
import Icon from './components/Icon.vue';

import router from './router.js';
import api from './plugins/api.js';

import App from './App.vue';
const app = createApp(App);

app.use(router);
app.use(api);
app.use(VueMatomo, {
  host: 'https://mamoto.mydid.com',
  siteId: 11,
  router,
  trackerUrl: 'https://mamoto.mydid.com/mydid-00000.php',
  trackerScriptUrl: 'https://mamoto.mydid.com/javascript.php',
  requireConsent: true,
});

app.provide('$cookies', VueCookies);
app.component('qrcode-vue', QrCodeVue);
app.component('custom-loader', ClipLoader);
app.component('icon', Icon);

app.mount('#app');
