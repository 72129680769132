<template>
  <div>
    <p class="text-xl font-bold mb-1">Bienvenue sur votre profil myDid</p>
    <p class="text-xl font-bold text-gray-500 mb-10">
      Votre compte myDid a été créé avec succès !
    </p>
    <div v-if="user" class="flex items-center mb-10">
      <div class="hidden lg:block flex-none w-[10rem]">
        <img :src="require('/assets/images/user.png')" alt="" class="w-full" />
      </div>
      <div class="grow pl-5">
        <!--<div class="flex gap-5 mb-3">
          <p class="text-xl leading-none">•</p>
          <p>
            <span class="font-bold">Pseudo</span> :
            {{
              user.verifiableCredentials
                .find(
                  (vc) =>
                    Object.keys(vc.credentialSubject).indexOf('award') != -1 &&
                    vc.credentialSubject['award'].startsWith('pseudo;')
                )
                .credentialSubject['award'].replace('pseudo; ', '')
            }}
          </p>
        </div>-->
        <div class="flex gap-5 mb-3">
          <p class="text-xl leading-none">•</p>
          <p>
            <span class="font-bold">DID</span> :
            {{ shortenString(user.did, 30) }}
          </p>
        </div>
      </div>
    </div>
    <p class="font-bold mb-1">
      Prochaine étape : Récompensez votre engagement !
    </p>
    <p class="mb-5">
      Vous êtes maintenant prêt à recevoir votre premier badge myDid pour
      célébrer votre participation à notre démo.
    </p>
    <div class="flex">
      <router-link to="/award">
        <div
          class="text-sm text-white text-center bg-secondary font-semibold w-56 rounded-lg py-3 px-5 cursor-pointer"
        >
          Recevoir mon badge
        </div>
      </router-link>
    </div>
  </div>
</template>

<script>
import { ref, inject } from 'vue';
export default {
  setup() {
    const $cookies = inject('$cookies');
    const $api = inject('$api');
    const user = ref(null);

    function logout() {
      $cookies.remove('token');
      window.location.replace('/');
    }

    function shortenString(str, maxLength) {
      if (str.length <= maxLength) {
        return str;
      }

      var middle = Math.floor(maxLength / 2);
      return str.substr(0, middle) + '...' + str.substr(str.length - middle);
    }

    $api.getUser().then((res) => {
      user.value = res.data;
    });

    return {
      user,
      logout,
      shortenString,
    };
  },
};
</script>
