import { createRouter, createWebHistory } from 'vue-router';
import VueCookies from 'vue-cookies';

import Home from './views/Home';
import Sign from './views/Sign';
import User from './views/User';
import Award from './views/Award';
import Admin from './views/Admin';
import Protected from './views/Protected';

const router = createRouter({
  history: createWebHistory(),
  routes: [
    { path: '/', component: Home },
    { path: '/sign', component: Sign },
    { path: '/user', component: User },
    { path: '/award', component: Award },
    { path: '/admin', component: Admin },
    {
      path: '/protected',
      component: Protected,
    },
    { path: '/:notFound(.*)', redirect: '/' },
  ],
});

router.beforeEach((to, from, next) => {
  // password protection
  if (process.env.VUE_APP_PASSWORD && process.env.VUE_APP_PASSWORD.length > 0) {
    const passwordValid =
      VueCookies.get('pwd') &&
      VueCookies.get('pwd') == process.env.VUE_APP_PASSWORD;

    if (passwordValid && to.path == '/protected') {
      return router.push('/');
    }
    if (!passwordValid) {
      VueCookies.remove('pwd');
      if (to.path != '/protected') return router.push('/protected');
    }
  }

  if (to.path == '/protected') return next();

  if (
    (to.path == '/user' || to.path == '/admin' || to.path == '/award') &&
    !VueCookies.get('token')
  ) {
    return router.push('/');
  }
  if (
    to.path != '/user' &&
    to.path != '/admin' &&
    to.path != '/award' &&
    VueCookies.get('token')
  ) {
    return router.push('/user');
  }
  return next();
});

export default router;
