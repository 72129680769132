<template>
  <div class="w-full mx-auto p-10 my-10">
    <p class="text-center text-xl text-gray-700 font-bold mb-10">
      Protection par mot de passe
    </p>
    <p class="text-center text-sm mb-3">Veuillez entrer le mot de passe ci-dessous :</p>
    <div class="flex justify-center mb-3">
      <input
        type="password"
        :placeholder="'Entrer le mot de passe'"
        class="w-64 outline-none bg-gray-200 text-sm rounded-lg border-none p-3"
        v-model="inputPassword"
        @keyup.enter="submitPassword"
      />
    </div>
    <p v-if="wrongPassword" class="text-red-500 text-center text-sm mb-3">
      Mot de passe incorrect
    </p>
    <div class="flex justify-center">
      <button
        class="text-sm bg-gray-800 text-white font-bold rounded-lg cursor-pointer py-3 px-5"
        @click="submitPassword"
      >
        Envoyer
      </button>
    </div>
  </div>
</template>

<script>
import { ref, inject } from 'vue';
import { useRouter } from 'vue-router';
export default {
  setup() {
    const $cookies = inject('$cookies');
    const router = useRouter();
    const inputPassword = ref('');
    const wrongPassword = ref(false);

    function submitPassword() {
      wrongPassword.value = false;
      if (inputPassword.value != process.env.VUE_APP_PASSWORD) {
        wrongPassword.value = true;
        return;
      }
      $cookies.set('pwd', inputPassword.value, '7d');
      router.push('/');
    }

    return { inputPassword, wrongPassword, submitPassword };
  },
};
</script>
