<template>
  <div
    v-if="showCookieConsent"
    class="absolute w-screen md:flex justify-center items-center bottom-0 bg-white px-5 border-t-2 z-30 py-5 md:px-20"
  >
    <p class="mb-5 md:mb-0">
      Nous utilisons des cookies et des technologies similaires pour vous offrir
      une expérience personnalisée et pour analyser notre trafic web. Pour plus
      d'informations, veuillez consulter notre
      <a href="https://mydid.com/legal/cookies-us.html" target="_blank"
        ><span class="underline">Politique de cookies.</span></a
      >
    </p>
    <div class="grow"></div>
    <div class="w-[250px] flex-none grid grid-cols-2 gap-5">
      <div>
        <button
          class="w-full text-sm bg-white text-gray-400 underline font-medium rounded-lg cursor-pointer py-1.5 px-2 ml-auto mr-3"
          @click="setCookieConsent(false)"
        >
          REFUSER
        </button>
      </div>
      <div>
        <button
          class="w-full text-sm bg-secondary text-white shadow-sm font-medium rounded-lg cursor-pointer py-1.5 px-2"
          @click="setCookieConsent(true)"
        >
          ACCEPTER
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, inject } from 'vue';
export default {
  setup(props) {
    const cookies = inject('$cookies');

    const showCookieConsent = ref(false);

    const consent = cookies.get('consent');
    if (!consent) {
      showCookieConsent.value = true;
    } else {
      consent == 1
        ? window._paq.push(['rememberConsentGiven'])
        : window._paq.push(['forgetConsentGiven']);
    }

    function setCookieConsent(isConsentOk) {
      cookies.set('consent', isConsentOk ? 1 : 0, isConsentOk ? '1y' : '1d');
      isConsentOk
        ? window._paq.push(['rememberConsentGiven'])
        : window._paq.push(['forgetConsentGiven']);
      showCookieConsent.value = false;
    }

    return { showCookieConsent, setCookieConsent };
  },
};
</script>
