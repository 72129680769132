<template>
  <div v-if="shortenLinkMydid">
    <div>
      <div
        v-if="showSignRedirect"
        class="fixed top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 bg-white px-5 py-4 border rounded-xl shadow-md z-10"
      >
        <div
          @click="showSignRedirect = false"
          class="flex justify-end text-gray-400 cursor-pointer leading-none"
        >
          <icon name="cross" class="w-5 fill-gray-400" />
        </div>
        <div v-if="!isSignin" class="p-5">
          <p class="mb-7">
            Il semblerait que vous êtes déjà inscrit, cliquez ci-dessous pour
            aller sur la page de connexion.
          </p>
          <div class="flex justify-center">
            <router-link to="/sign?account=1"
              ><p
                class="text-white text-center bg-secondary font-semibold w-56 rounded-lg py-3 px-5 cursor-pointer"
              >
                Page de connexion
              </p></router-link
            >
          </div>
        </div>
        <div v-else class="p-5">
          <p class="mb-7">
            Il semblerait que vous n'êtes pas encore inscrit, cliquez ci-dessous
            pour aller sur la page d'inscription.
          </p>
          <div class="flex justify-center">
            <router-link to="/sign?account=0"
              ><p
                class="text-white text-center bg-secondary font-semibold w-56 rounded-lg py-3 px-5 cursor-pointer"
              >
                Page d'inscription
              </p></router-link
            >
          </div>
        </div>
      </div>
    </div>
    <div v-if="isSignin">
      <p class="text-xl font-bold mb-1">Accédez à votre Compte myDid</p>
      <p class="text-xl font-bold text-gray-500 mb-5">
        Bienvenue sur la page de connexion myDid !
      </p>
      <p class="mb-10">
        Connectez-vous pour accéder à votre profil et interagir avec notre
        plateforme :
      </p>
    </div>
    <div v-else>
      <p class="text-xl font-bold mb-1">Créez votre compte</p>
      <p class="text-xl font-bold text-gray-500 mb-5">
        Entrez dans l'ère de la sécurité numérique
      </p>
      <p class="mb-10">Suivez ces étapes simples pour créer votre compte :</p>
    </div>
    <div class="flex bg-white rounded-full">
      <div
        class="hidden lg:block flex-none w-[18rem] border-b bg-[url('/assets/images/phone-frame.png')] bg-cover p-5 pt-20"
      >
        <qrcode-vue
          v-if="shortenLinkMydid"
          :value="shortenLinkMydid"
          :size="200"
          level="H"
          class="bg-white mx-auto mb-5"
        />
        <div class="flex justify-center mb-3">
          <icon name="scan" class="w-4 fill-black mr-2" />Scannez avec myDid
        </div>
      </div>
      <div class="grow pl-5">
        <div v-if="isSignin">
          <div class="flex gap-5 mb-3">
            <p class="text-xl leading-none">•</p>
            <p class="hidden lg:block">
              <span class="font-bold">Scan du QR Code </span> : Scannez le QR
              Code affiché sur cette page avec l'application myDid pour vous
              connecter facilement et en toute sécurité.
            </p>
            <p class="lg:hidden">
              <span class="font-bold">Cliquez sur "Ouvrir l'app"</span> :
              Cliquez pour pour ouvrir l'application myDid et vous connecter
              facilement et en toute sécurité.
            </p>
          </div>
          <div class="flex gap-5 mb-3">
            <p class="text-xl leading-none">•</p>
            <p class="hidden lg:block">
              <span class="font-bold">Accès Direct</span> : Une fois le QR Code
              scanné, vous serez directement connecté à votre compte myDid.
            </p>
            <p class="lg:hidden">
              <span class="font-bold">Accès Direct</span> : Une fois le lien
              cliqué, vous serez directement connecté à votre compte myDid.
            </p>
          </div>
          <div class="flex gap-5 mb-10">
            <p class="text-xl leading-none">•</p>
            <p>
              <span class="font-bold">Pas encore de Compte myDid ?</span>
              Rendez-vous sur la page d'inscription pour créer votre profil. La
              création d'un compte est simple et rapide, c’est une étape
              cruciale pour obtenir votre badge d'innovation myDid !
            </p>
          </div>
        </div>
        <div v-else>
          <div class="flex gap-5 mb-3">
            <p class="text-xl leading-none">•</p>
            <p class="hidden lg:block">
              <span class="font-bold">Scannez le QR Code</span> : Utilisez votre
              application myDid pour scanner le code. Cela ouvrira la porte à
              votre nouvelle identité numérique.
            </p>
            <p class="lg:hidden">
              <span class="font-bold">Cliquez sur "Ouvrir l'app"</span> :
              Cliquez pour pour ouvrir l'application myDid. Cela ouvrira la
              porte à votre nouvelle identité numérique.
            </p>
          </div>
          <div class="flex gap-5 mb-3">
            <p class="text-xl leading-none">•</p>
            <p class="hidden lg:block">
              <span class="font-bold">Pas encore l'application ?</span> Le scan
              du QR Code vous dirigera vers le téléchargement de l'application
              myDid. Installez-la et continuez votre chemin vers l'innovation.
            </p>
            <p class="lg:hidden">
              <span class="font-bold">Pas encore l'application ?</span> Le clic
              sur le bouton vous dirigera vers le téléchargement de
              l'application myDid. Installez-la et continuez votre chemin vers
              l'innovation.
            </p>
          </div>
          <div class="flex gap-5 mb-10">
            <p class="text-xl leading-none">•</p>
            <p class="hidden lg:block">
              <span class="font-bold">Créez votre compte</span> en suivant les
              instructions après le scan. C'est une étape cruciale pour obtenir
              votre badge d'innovation myDid !
            </p>
            <p class="lg:hidden">
              <span class="font-bold">Créez votre compte</span> en suivant les
              instructions après le clic. C'est une étape cruciale pour obtenir
              votre badge d'innovation myDid !
            </p>
          </div>
        </div>
        <div v-if="shortenLinkMydid" class="flex lg:hidden ml-2 mb-3">
          <a :href="shortenLinkMydid"
            ><p
              class="text-sm text-white text-center bg-secondary font-semibold w-56 rounded-lg py-3 px-5 cursor-pointer"
            >
              Ouvrir l'app
            </p></a
          >
        </div>
        <div class="flex ml-2">
          <router-link to="/"
            ><p
              class="text-sm text-center text-gray-700 border border-gray-500 w-56 rounded-lg py-3 px-5 cursor-pointer"
            >
              Retour
            </p></router-link
          >
        </div>
      </div>
    </div>
  </div>
  <custom-loader v-else color="#111827" size="70px" class="mt-10">
  </custom-loader>
</template>

<script>
import { ref, inject, onBeforeUnmount } from 'vue';
import { useRoute } from 'vue-router';
import { createToast } from 'mosha-vue-toastify';

export default {
  setup() {
    const $api = inject('$api');
    const $cookies = inject('$cookies');
    const route = useRoute();
    const shortenLinkMydid = ref('');
    const showSignRedirect = ref(false);
    const loading = ref(null);
    const isSignin = ref(route.query.account == 1);

    onBeforeUnmount(() => {
      $api.closeChallengeValidation();
    });

    $api[
      isSignin.value ? 'createChallengeSignin' : 'createChallengeSignup'
    ]().then((res) => {
      const newChallenge = res.data;
      const appLink =
        'https://mydid.link?data=' +
        encodeURIComponent(JSON.stringify(newChallenge));

      $api.waitChallengeValidation(newChallenge.challenge, (response) => {
        switch (response.status) {
          case 'waiting':
            loading.value = true;
            break;
          case 'treating':
            loading.value = true;
            break;
          case 'expired':
            if (
              response.message == 'Account already exists' ||
              response.message == `Account doesn't exist yet`
            )
              showSignRedirect.value = true;
            loading.value = false;
            break;
          case 'validated':
            $cookies.set('token', response.token);
            loading.value = false;
            window.location.replace('/user');
            break;
        }
      });

      $api.more
        .shortenUrl('/?data=' + encodeURIComponent(appLink))
        .then((response) => {
          shortenLinkMydid.value = response.dynamicLink;
        });
    });

    function isMobile() {
      let check = false;
      (function (a) {
        if (
          /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(
            a
          ) ||
          /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
            a.substr(0, 4)
          )
        )
          check = true;
      })(navigator.userAgent || navigator.vendor || window.opera);
      return check;
    }

    return {
      shortenLinkMydid,
      loading,
      isMobile,
      isSignin,
      showSignRedirect,
    };
  },
};
</script>
